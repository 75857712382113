/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import d3 from "d3";

const { iso8601 } = require("components/relevant/utilities/dates");
import _ from "lodash";

const DateChartDimension = function (...args) {
  const argsWithBoundedArgs = { ...args[0], ...args[1] };
  const {
    options = {},
    unique_axis_values,
    accessor,
    width,
  } = argsWithBoundedArgs;

  const self = {};

  self.unique_axis_values = unique_axis_values;
  self.accessor = accessor;

  self.parser = iso8601.parse;
  self.parsed_accessor = function (d) {
    return self.parser(self.accessor(d));
  };
  self.parsed_unique_axis_values = self.unique_axis_values.map(self.parser);

  const min = _.get(
    options,
    "fixedMin",
    d3.min(self.parsed_unique_axis_values)
  );
  const max = _.get(
    options,
    "fixedMax",
    d3.max(self.parsed_unique_axis_values)
  );

  self.scale = d3.time.scale().range([0, width]).domain([min, max]);

  self.axis = d3.svg.axis().scale(self.scale);

  if (_.has(options, "removeXAxisTicks") && options.removeXAxisTicks) {
    self.axis.tickFormat("").tickSize(0, 0);
  }

  self.axis.orient("bottom");

  self.index_from_mouse_x = function (mouse_x) {
    const mouse_date = self.scale.invert(mouse_x);
    const closest_date_to_mouse = _.minBy(self.parsed_unique_axis_values, (d) =>
      Math.abs(mouse_date - d)
    );
    return _.findIndex(
      self.parsed_unique_axis_values,
      (d) => d === closest_date_to_mouse
    );
  };
  self.axis_value_from_mouse_x = function (mouse_x) {
    return self.unique_axis_values[self.index_from_mouse_x(mouse_x)];
  };

  return self;
};

const OrdinalChartDimension = function ({
  unique_axis_values,
  accessor,
  width,
}) {
  const self = {};

  self.unique_axis_values = unique_axis_values;
  self.accessor = accessor;

  self.parser = (d) => d;
  self.parsed_accessor = function (d) {
    return self.parser(self.accessor(d));
  };
  self.parsed_unique_axis_values = self.unique_axis_values.map(self.parser);
  self.scale = d3.scale
    .ordinal()
    .rangePoints([0, width])
    .domain(self.unique_axis_values);
  self.axis = d3.svg.axis().scale(self.scale).orient("bottom");
  self.index_from_mouse_x = function (mouse_x) {
    const unit_width = width / (self.unique_axis_values.length - 1);
    return Math.round(mouse_x / unit_width);
  };
  self.axis_value_from_mouse_x = function (mouse_x) {
    return self.unique_axis_values[self.index_from_mouse_x(mouse_x)];
  };

  return self;
};

export { DateChartDimension, OrdinalChartDimension };
