/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
import d3 from "d3";
import _ from "lodash";
import Relevant from "components/relevant";

let tooltip_hbs_template = require("./tooltip_hbs_template.handlebars");

const TrendSparkLine = function () {
  let margin = { top: 10, right: 250, bottom: 80, left: 50 };
  let width = 1000;
  let height = 500;

  const color_function = d3.scale.category20c();
  const dot_size = 1;
  const stroke_width = 3;

  let y_axis_label = "Visits";
  let y_value_formatter = (d) => d;

  const series_name_accessor = function (d) {
    if (d) {
      return d.series_name;
    }
    return "";
  };

  const x_value_accessor = function (d) {
    if (d) {
      return d.label;
    }
    return "";
  };
  let y_value_accessor = function (d) {
    if (d) {
      return +d.visit_count;
    }
    return 0;
  };
  const x_value_setter = function (d, val) {
    if (d) {
      d.label = val;
      return d;
    }
    return "";
  };
  const y_value_setter = function (d, val) {
    if (d) {
      d.visit_count = val;
      return d || 0;
    }
    return 0;
  };

  let responsive_width = true;
  let responsive_height = true;
  const percentage_of_innerHeight = 0.6;

  let render_legend = true;
  let render_axes = true;

  let show_tooltip = false;

  const handle_sparse_data = true;

  let currently_selected_label = "";
  let reset_selection_function = (d) => d;

  const dispatch = d3.dispatch("time_series_selection_change");

  let target_percentage = null;

  var chart = function (selection) {
    selection.each(function (data, index) {
      let tooltip;
      let xAxis;
      let yAxis;
      const selected = data[0].values.filter(
        (d) => x_value_accessor(d) === currently_selected_label
      )[0];

      if (handle_sparse_data) {
        data = Relevant.SparseDataHandler(
          data,
          x_value_accessor,
          x_value_setter,
          y_value_setter
        );
      }

      if (responsive_width || responsive_height) {
        if (responsive_width) {
          width = $(selection[index][0]).width();
        }
        if (responsive_height) {
          height = window.innerHeight * percentage_of_innerHeight;
        }

        // Bind a callback to the resize event on the initial drawing of the chart.
        if (selection.select("svg").empty()) {
          let timeout = 0;
          $(window).resize(() => {
            clearTimeout(timeout);
            return (timeout = setTimeout(() => chart(selection), 300));
          });
        }
      }

      const inner_width = width - margin.left - margin.right;
      const inner_height = height - margin.top - margin.bottom;

      const x_axis_values = Relevant.UniqueXAxisValues(data, x_value_accessor);

      // Use the color_function to build a hash from series names to colors, so
      // we can pass around a color_picker closure that looks up values from that hash.
      // This is more resilient than assigning colors based on array index.
      const color_hash = {};
      data.forEach(
        (series, index) =>
          (color_hash[series_name_accessor(series)] = color_function(index))
      );

      const color_picker = (d) => color_hash[d];

      // Set up domains, scales, ranges, and axes
      const min = d3.min(
        data.map((d) => d3.min(d.values.map((d) => y_value_accessor(d))))
      );
      const max = d3.max(
        data.map((d) => d3.max(d.values.map((d) => y_value_accessor(d))))
      );

      const x = d3.scale.ordinal().rangePoints([0, inner_width]);
      const y = d3.scale.linear().rangeRound([inner_height, 0]);

      x.domain(x_axis_values);

      // scale the y axis so it will include the target line if the target is
      // drastically above or below the measures max or min values
      const targetAsDecimal = target_percentage / 100;
      const maxOrTarget = Math.max(targetAsDecimal, max);
      const minOrTarget = Math.min(targetAsDecimal, min);

      y.domain([minOrTarget * 0.8, maxOrTarget * 1.05]);

      if (render_axes) {
        xAxis = d3.svg.axis().scale(x).orient("bottom");
        yAxis = d3.svg
          .axis()
          .scale(y)
          .orient("left")
          .tickFormat(y_value_formatter);
      }

      // Chart skeleton
      const svg = selection.selectAll("svg").data([data]);

      const svg_enter = svg.enter().append("svg").attr("class", "main-svg");

      svg.attr("width", width).attr("height", height);

      const main_chart_group_enter = svg_enter
        .append("g")
        .attr("class", "main-chart-group");

      // Get a new reference to main-chart-group (in case it's a refresh)
      const main_chart_group = svg.select("g.main-chart-group");

      main_chart_group
        .attr("width", inner_width)
        .attr("height", inner_height)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      if (target_percentage) {
        const target_line = main_chart_group.selectAll("line.target").data([0]);

        target_line.enter().append("line");

        target_line
          .attr("class", "target")
          .attr("style", "stroke: rgb(98, 98, 98);")
          .attr("x1", 0)
          .attr("x2", inner_width)
          .attr("y1", y(target_percentage / 100))
          .attr("y2", y(target_percentage / 100));
      }

      // Tooltip setup
      if (show_tooltip) {
        const body_selection = d3.select("body");

        body_selection
          .selectAll("div.d3-tooltip")
          .data([0])
          .enter()
          .append("div")
          .attr("class", "d3-tooltip");

        tooltip = body_selection.select("div.d3-tooltip");
      }

      const fade_highlight_circle = () =>
        main_chart_group
          .selectAll("circle.highlight")
          .transition()
          .duration(200)
          .style("opacity", 0);

      if (render_axes) {
        const x_axis_enter = main_chart_group_enter
          .append("g")
          .attr("class", "x axis");

        const x_axis = main_chart_group
          .select("g.x")
          .attr("transform", `translate(0,${inner_height})`);

        const y_axis_enter = main_chart_group_enter
          .append("g")
          .attr("class", "y axis");

        y_axis_enter
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end")
          .text(y_axis_label);

        svg
          .select("g.x.axis")
          .call(xAxis)
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", () => "rotate(-65)");

        svg.select("g.y.axis").call(yAxis);
      }

      if (render_legend) {
        const legend_group_enter = svg_enter
          .append("g")
          .attr("class", "legend-group");

        const legend_group = svg
          .select("g.legend-group")
          .attr(
            "transform",
            `translate(${margin.left + inner_width},${margin.top})`
          );
      }

      // Series
      const series_group = main_chart_group.selectAll("g.series").data(data);

      const series_enter = series_group.enter().append("g");

      series_group
        .attr("class", (d) => `series series-${d.series_id}`)
        .style("fill", (d, i) => color_picker(series_name_accessor(d)))
        .style("stroke", (d, i) => color_picker(series_name_accessor(d)));

      series_group
        .exit()
        .transition()
        .duration(750)
        .style("opacity", 1e-6)
        .remove();

      // Data points: join
      const data_points = series_group.selectAll(".data-point").data(
        (d) => d.values,
        (d) => d.label + series_name_accessor(d)
      ); // use label_id and series_name to uniquely identify series

      // Data points: enter
      data_points
        .enter()
        .append("circle")
        .attr("class", (d) => `data-point time-id-${x_value_accessor(d)}`);

      // Data points: update
      data_points
        .attr("cx", (d) => x(x_value_accessor(d)))
        .attr("r", dot_size)
        .transition()
        .duration(400)
        .attr("cy", (d) => y(y_value_accessor(d)));

      data_points
        .exit()
        .transition()
        .duration(750)
        .style("opacity", 1e-6)
        .remove();

      // Define a line function
      const historical_line_function = d3.svg
        .line()
        .x((d) => x(x_value_accessor(d)))
        .y((d) => y(y_value_accessor(d)))
        .defined((d) => !d.projected);

      const projected_line_function = d3.svg
        .line()
        .x((d) => x(x_value_accessor(d)))
        .y((d) => y(y_value_accessor(d)))
        .defined((d) => d.projected || d.last_historical);

      // Lines: join
      const historical_lines = series_group.selectAll(".chart-line").data(
        (d) => [d.values],
        (d) => series_name_accessor(d)
      );

      const projected_lines = series_group
        .selectAll(".chart-projected-line")
        .data(
          (d) => [d.values],
          (d) => series_name_accessor(d)
        );

      // Lines: enter
      historical_lines
        .enter()
        .append("path")
        .style("opacity", 0)
        .style("stroke-width", stroke_width)
        .attr("class", "chart-line");

      projected_lines
        .enter()
        .append("path")
        .style("opacity", 0)
        .style("stroke-width", stroke_width)
        .style("stroke-dasharray", 3)
        .attr("class", "chart-projected-line")
        .attr("fill", "none");

      // Lines: update
      historical_lines
        .transition()
        .duration(400)
        .style("opacity", 1)
        .attr("d", (d) => historical_line_function(d));

      projected_lines
        .transition()
        .duration(400)
        .style("opacity", 1)
        .attr("d", (d) => projected_line_function(d));

      historical_lines.exit().remove();
      projected_lines.exit().remove();

      const mouse_listening_rect = main_chart_group
        .selectAll(".mouse-listening-rectangle")
        .data([0]);

      mouse_listening_rect
        .enter()
        .append("rect")
        .attr("class", "mouse-listening-rectangle")
        .attr("fill", "none")
        .attr("pointer-events", "all");

      mouse_listening_rect
        .attr("width", inner_width)
        .attr("height", inner_height);

      // Legend setup
      const legend = Relevant.Legend()
        .series_name_accessor(series_name_accessor)
        .y_value_formatter(y_value_formatter);

      main_chart_group
        .selectAll("circle.current-selection")
        .data([0])
        .enter()
        .append("circle")
        .attr("class", "current-selection");

      const selection_circle = main_chart_group.select(
        "circle.current-selection"
      );

      selection_circle
        .attr("r", dot_size * 5)
        .attr("cx", () => x(x_value_accessor(selected)))
        .attr("cy", () => y(y_value_accessor(selected)))
        .style("fill", (d, i) => color_function(i));

      main_chart_group
        .selectAll("circle.highlight")
        .data([0])
        .enter()
        .append("circle")
        .attr("class", "highlight");

      const highlight_circle = main_chart_group.select("circle.highlight");

      highlight_circle
        .attr("r", dot_size * 5)
        .attr("cx", () => x(x_value_accessor(selected)))
        .attr("cy", () => y(y_value_accessor(selected)))
        .style("opacity", 0)
        .style("fill", "none")
        .style("stroke", (d, i) => color_function(i));

      // Tooltip

      if (show_tooltip) {
        const all_circle_data = _.flatten(data.map((d) => d.values));

        const listening_rects = main_chart_group
          .selectAll("rect.listening-rect")
          .data(all_circle_data, (d) => d.label + series_name_accessor(d));

        listening_rects
          .enter()
          .append("rect")
          .attr("class", (d) => "listening-rect clickable")
          .attr("fill", "none")
          .attr("stroke", "none")
          .attr("z-index", 10)
          .attr("pointer-events", "all");

        listening_rects
          .attr("width", 5.3)
          .attr("height", 30)
          .attr("x", (d) => x(x_value_accessor(d)) - 2)
          .attr("y", (d) => y(y_value_accessor(d)) - 15);

        listening_rects.exit().remove();

        listening_rects
          .on("mouseover", function (d) {
            draw_guideline_and_update_legend(
              this.x.baseVal.value,
              this.y.baseVal.value
            );

            const tooltip_data = { ...d };
            tooltip_data.target_percentage_message = target_percentage
              ? `Compliance target ${target_percentage}%`
              : "No target compliance set";
            tooltip_data.compliance_percentage = d3.format(".1%")(
              tooltip_data.compliance_percentage
            );
            return tooltip
              .html(() => tooltip_hbs_template(tooltip_data))
              .style("left", `${d3.event.pageX + 15}px`)
              .style("top", `${d3.event.pageY + 5}px`)
              .style("visibility", "visible");
          })
          .on("mouseout", function (d) {
            fade_highlight_circle();
            return tooltip.style("visibility", "hidden");
          })
          .on("click", function (d) {
            reset_selection_function(x_value_accessor(d));
            return (currently_selected_label = x_value_accessor(d));
          });
      }

      var draw_guideline_and_update_legend = function (x, y) {
        main_chart_group.selectAll("circle.data-point").attr("r", dot_size);
        return main_chart_group
          .selectAll("circle.highlight")
          .attr("cx", x + 2)
          .attr("cy", y + 15)
          .attr("r", 10)
          .transition()
          .duration(50)
          .style("opacity", 1);
      };

      mouse_listening_rect.on("mouseout", () => fade_highlight_circle());
    });

    return chart;
  };

  chart.margin = function (margin_object) {
    if (!arguments.length) {
      return margin;
    }
    margin = margin_object;
    return chart;
  };

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.height = function (value) {
    if (!arguments.length) {
      return height;
    }
    height = value;
    return chart;
  };

  chart.y_axis_label = function (value) {
    if (!arguments.length) {
      return y_axis_label;
    }
    y_axis_label = value;
    return chart;
  };

  chart.show_tooltip = function (boolean) {
    if (!arguments.length) {
      return show_tooltip;
    }
    show_tooltip = boolean;
    return chart;
  };

  chart.tooltip_hbs_template = function (value) {
    if (!arguments.length) {
      return tooltip_hbs_template;
    }
    tooltip_hbs_template = value;
    return chart;
  };

  chart.y_value_accessor = function (funktion) {
    if (!arguments.length) {
      return y_value_accessor;
    }
    y_value_accessor = funktion;
    return chart;
  };

  chart.y_value_formatter = function (funktion) {
    if (!arguments.length) {
      return y_value_formatter;
    }
    y_value_formatter = funktion;
    return chart;
  };

  chart.responsive_width = function (boolean) {
    if (!arguments.length) {
      return responsive_width;
    }
    responsive_width = boolean;
    return chart;
  };

  chart.responsive_height = function (boolean) {
    if (!arguments.length) {
      return responsive_height;
    }
    responsive_height = boolean;
    return chart;
  };

  chart.render_legend = function (boolean) {
    if (!arguments.length) {
      return render_legend;
    }
    render_legend = boolean;
    return chart;
  };

  chart.render_axes = function (boolean) {
    if (!arguments.length) {
      return render_axes;
    }
    render_axes = boolean;
    return chart;
  };

  chart.target_percentage = function (value) {
    if (!arguments.length) {
      return target_percentage;
    }
    target_percentage = value;
    return chart;
  };

  chart.currently_selected_label = function (value) {
    if (!arguments.length) {
      return currently_selected_label;
    }
    currently_selected_label = value;
    return chart;
  };

  chart.reset_selection_function = function (funktion) {
    if (!arguments.length) {
      return reset_selection_function;
    }
    reset_selection_function = funktion;
    return chart;
  };

  chart.dispatch = dispatch;

  return chart;
};

export default TrendSparkLine;
