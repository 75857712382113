import toastr from "toastr";

toastr.options.preventDuplicates = true;
toastr.options.timeOut = 0;
toastr.options.extendedTimeOut = 0;
toastr.options.showMethod = "slideDown";

function redToast(
  errorTitle = "Well, this is embarrassing...",
  errorMessage = "An error has occurred; please refresh the page."
) {
  return toastr.error(errorMessage, errorTitle);
}

function yellowToast() {
  const errorTitle = "Session expired";
  const errorMessage =
    "You will need to log in again; please refresh the page.";
  return toastr.warning(errorMessage, errorTitle);
}

export { redToast, yellowToast };
