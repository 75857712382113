/* eslint-disable */
import BarChart from "./generic/bar_chart";
import BinaryStaticPieChart from "./generic/binary_static_pie_chart";
import CalendarHeatmap from "./generic/calendar_heatmap";
import HorizontalBarChart from "./generic/horizontal_bar_chart";
import HorizontalBarChartWithNegatives from "./generic/horizontal_bar_chart_with_negatives";
import Legend from "./generic/legend";
import MultiSeriesLineChart from "./generic/multi_series_line_chart";
import TrendSparkLine from "./specific/trend_spark_line";

const Charts = {
  BarChart,
  BinaryStaticPieChart,
  CalendarHeatmap,
  HorizontalBarChart,
  HorizontalBarChartWithNegatives,
  Legend,
  MultiSeriesLineChart,
  TrendSparkLine,
};

export default Charts;
