import Datepicker from "./datepicker";
import Dropdown from "./dropdown";
import Selectbox from "./selectbox";
import Tooltip from "./tooltip";

const Behaviors = {
  Datepicker,
  Dropdown,
  Selectbox,
  Tooltip,
};

export default Behaviors;
