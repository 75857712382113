/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
import d3 from "d3";

const HorizontalBarChart = function () {
  // Set defaults for accessors
  let width = 600;
  let min_height = 500;
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };

  let calculated_total_height = null;

  let responsive_width = false;
  let responsive_bar_heights = false;
  const max_responsive_bar_line_height = 65;

  let bar_line_height = 20;
  let bar_height = 15;
  const bar_label_font_size = 11;

  let bar_label_width = "dynamic";
  const bar_annotation_width = 45;
  const bar_text_margin = 5;

  let colorFunction = (item_id, index) => "#00FF00";

  let highlightedFunction = (d) => false;
  let dimmedFunction = (d) => false;
  let tooltipFunction = null;
  let labelTooltipFunction = null;

  const transition_duration = 500;
  const enter_exit_animation_speed = transition_duration / 2;

  let label_accessor = (d) => d.label;
  let label_id_accessor = (d) => d.label_id;
  let value_accessor = (d) => d.value;
  let value_formatter = (d) => d;
  let toggleItemHighlight = null;
  let fixed_max_value = null;

  // A property that points to an anonymous function that draws the chart.
  //
  //    selection - A D3 selection containing one or more DOM nodes within which we want the chart to be drawn.
  //                These node(s) [typically only one, but could be more if you want to draw the chart multiple times]
  //                should already have their __data__ element set to the chart's data.
  //
  // The function returns the outer variable that defines it, so calls to it can be chained -- or something. TODO: clarify this.
  var chart = (selection) =>
    // Expects an array as follows:
    //
    //   [
    //     {label: "Bar Corvo", label_id: 1, value: 10},
    //     {label: "Beer Bar", label_id: 2, value: 100},
    //     {label: "Milk and Honey", label_id: 3, value: 90},
    //     {label: "Old Time Tavern", label_id: 4, value: 80}
    //   ]
    //
    // The accessors for: label, label_id and value can be overriden using:
    //   label_accessor, label_id_accessor and value_accessor respectively
    //
    selection.each(function (data, index) {
      // TODO: this responsive width/height stuff should get packaged up into some kind of javascript include thing.
      if (responsive_width) {
        width = $(selection[index][0]).width();

        // Bind a callback to the resize event on the initial drawing of the chart.
        // TODO: replace this with _.debounce
        if (selection.select("svg").empty()) {
          let timeout = 0;
          $(window).resize(() => {
            clearTimeout(timeout);
            return (timeout = setTimeout(() => chart(selection), 300));
          });
        }
      }

      const available_width = width - margin.left - margin.right;

      calculated_total_height =
        bar_line_height * data.length + margin.top + margin.bottom;
      const height = d3.max([calculated_total_height, min_height]);
      const available_height = height - margin.top - margin.bottom; // Question: what is available_height used for? (except the new code for responsive width, below?) -JH

      // Calculate responsive bar heights.
      // Only takes action to fill up min-height if there's extra/unused room within min-height.
      if (responsive_bar_heights && min_height > calculated_total_height) {
        bar_line_height = d3.min([
          max_responsive_bar_line_height,
          available_height / data.length,
        ]);
        bar_height = bar_line_height * 0.85;
      }

      // Sort: first by value_accessor DESC, then break ties with an ASC alpha sort of label text.
      data = data.sort(function (a, b) {
        const sorter = value_accessor(b) - value_accessor(a);

        if (sorter === 0 && label_accessor(a) && label_accessor(b)) {
          return label_accessor(a).localeCompare(label_accessor(b));
        }
        return sorter;
      });

      const bar_scale = d3.scale.linear();
      bar_scale.domain([
        0,
        fixed_max_value || d3.max(data, (d) => value_accessor(d)),
      ]);

      if (bar_label_width === "dynamic") {
        // From the negative HorizontalBarChart code
        // Helps the text from being cut off in the chart when it's really long
        const text_width =
          d3.max(data, (d) =>
            Relevant.textWidth(label_accessor(d), "11px sans-serif")
          ) + 15;
        bar_label_width = d3.min([text_width, width * 0.6]);
      }

      bar_scale.range([
        0,
        available_width - bar_label_width - bar_annotation_width,
      ]); // scale output values to leave room for text element to left and right

      // Chart skeleton
      const svg = d3.select(this).selectAll("svg").data([data]); // flow all of the data into an <svg>, if it exists. We use the array so we're asking for one <svg>, not one per datum.

      const svg_enter = svg
        .enter()
        .append("svg")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      svg.attr("width", width).attr("height", height);

      svg_enter.append("g").attr("class", "bars");

      const bar_group = svg.select("g.bars");

      svg_enter.append("g").attr("class", "bar-labels");

      const bar_label_group = svg.select("g.bar-labels");

      svg_enter.append("g").attr("class", "bar-value-annotations");

      const bar_annotation_group = svg.select("g.bar-value-annotations");

      // Bars
      const bars = bar_group
        .selectAll("rect")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      // Bars: enter
      bars
        .enter()
        .append("rect")
        .style("fill", (d, i) => colorFunction(label_id_accessor(d), i))
        .style("opacity", 0.1)
        .attr("data-test-id", (d) => label_id_accessor(d));

      // Bars: update
      bars.attr("height", bar_height).attr("x", bar_label_width); // position bars to right of labels

      bars
        .classed("dimmed", (d) => dimmedFunction(label_id_accessor(d)))
        .classed("selected", (d) => highlightedFunction(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggleItemHighlight) {
            toggleItemHighlight(label_id_accessor(d));
          }
        });

      bars
        .transition()
        .duration(transition_duration)
        .style("opacity", 1)
        .attr("width", (d) => bar_scale(value_accessor(d)))
        .attr(
          "y",
          (d, i) => bar_line_height * i + (bar_line_height - bar_height) / 2
        );

      if (toggleItemHighlight) {
        bars.classed("clickable", true);
      }

      bars
        .exit()
        .transition()
        .duration(enter_exit_animation_speed)
        .style("opacity", 0.1)
        .delay(enter_exit_animation_speed)
        .remove();

      // Bar labels
      const bar_labels = bar_label_group
        .selectAll("text")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      // Bar labels: enter
      bar_labels
        .enter()
        .append("text")
        .style("opacity", 0)
        .attr("class", "bar-chart-bar-label")
        .attr("style", `font-size: ${bar_label_font_size}px;`)
        .text((d) => label_accessor(d));

      // Bar labels: update
      bar_labels
        .classed("dimmed", (d) => dimmedFunction(label_id_accessor(d)))
        .classed("selected", (d) => highlightedFunction(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggleItemHighlight) {
            toggleItemHighlight(label_id_accessor(d));
          }
        });

      bar_labels.attr("x", bar_label_width - bar_text_margin);

      bar_labels
        .transition()
        .duration(transition_duration)
        .style("opacity", 1)
        .attr("y", function (d, i) {
          // The y coordinate specifies the text's baseline, not the top of the bounding box; see http://www.w3.org/TR/SVG/text.html#TSpanElementYAttribute.
          let text_baseline;
          const center_of_bar_line = bar_line_height * i + bar_line_height / 2;
          return (text_baseline = center_of_bar_line + bar_label_font_size / 2);
        });

      bar_labels
        .exit()
        .transition()
        .duration(enter_exit_animation_speed)
        .style("opacity", 0.1)
        .delay(enter_exit_animation_speed)
        .remove();

      // Bar annotations
      const bar_annotations = bar_annotation_group
        .selectAll("text")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      // Bar annotations: enter
      bar_annotations
        .enter()
        .append("text")
        .attr("class", "bar-chart-bar-value")
        .attr("style", `font-size: ${bar_label_font_size}px;`)
        .style("opacity", 0);

      // Bar annotations: update
      bar_annotations
        .text((d) => value_formatter(value_accessor(d)))
        .classed("dimmed", (d) => dimmedFunction(label_id_accessor(d)))
        .classed("selected", (d) => highlightedFunction(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggleItemHighlight) {
            toggleItemHighlight(label_id_accessor(d));
          }
        });

      bar_annotations
        .transition()
        .duration(transition_duration)
        .style("opacity", 1)
        .attr(
          "x",
          (d) =>
            bar_scale(value_accessor(d)) + bar_label_width + bar_text_margin
        )
        .attr("y", function (d, i) {
          let text_baseline;
          const center_of_bar_line = bar_line_height * i + bar_line_height / 2;
          return (text_baseline = center_of_bar_line + bar_label_font_size / 2);
        });

      bar_annotations
        .exit()
        .transition()
        .duration(enter_exit_animation_speed)
        .style("opacity", 0.1)
        .delay(enter_exit_animation_speed)
        .remove();

      const body_selection = d3.select("body");

      body_selection
        .selectAll("div.d3-tooltip")
        .data([0])
        .enter()
        .append("div")
        .attr("class", "d3-tooltip")
        .style("position", "absolute")
        .style("opacity", 0);

      const tooltip_div = body_selection.select("div.d3-tooltip");

      bars.on("mousemove", function (_d, i) {
        Array.from(d3.mouse(this));
        const d = data[i];

        if (tooltipFunction) {
          tooltip_div
            .transition()
            .duration(500)
            .style("opacity", 100)
            .style("visibility", "visible");

          return tooltip_div
            .html(tooltipFunction(d))
            .style("left", `${d3.event.pageX + 16}px`)
            .style("top", `${d3.event.pageY}px`);
        }
      });

      bars.on("mouseout", (d) => {
        tooltip_div.transition().duration(500).style("opacity", 0);
      });

      // bar label tooltips
      bar_labels.on("mousemove", function (_d, i) {
        Array.from(d3.mouse(this));
        const d = data[i];
        const tooltipContents = labelTooltipFunction(d);

        if (labelTooltipFunction && tooltipContents) {
          tooltip_div
            .transition()
            .duration(500)
            .style("opacity", 100)
            .style("visibility", "visible");

          return tooltip_div
            .html(tooltipContents)
            .style("left", `${d3.event.pageX + 16}px`)
            .style("top", `${d3.event.pageY}px`);
        }
      });

      bar_labels.on("mouseout", (d) => {
        tooltip_div.transition().duration(500).style("opacity", 0);
      });

      return chart;
    });

  chart.margin = function (value) {
    if (!arguments.length) {
      return margin;
    }
    margin = value;
    return chart;
  };

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.min_height = function (value) {
    if (!arguments.length) {
      return min_height;
    }
    min_height = value;
    return chart;
  };

  chart.value_accessor = function (f) {
    if (!arguments.length) {
      return value_accessor;
    }
    value_accessor = f;
    return chart;
  };

  chart.label_accessor = function (f) {
    if (!arguments.length) {
      return label_accessor;
    }
    label_accessor = f;
    return chart;
  };

  chart.label_id_accessor = function (f) {
    if (!arguments.length) {
      return label_id_accessor;
    }
    label_id_accessor = f;
    return chart;
  };

  chart.value_formatter = function (funktion) {
    if (!arguments.length) {
      return value_formatter;
    }
    value_formatter = funktion;
    return chart;
  };

  chart.colorFunction = function (funktion) {
    if (!arguments.length) {
      return colorFunction;
    }
    colorFunction = funktion;
    return chart;
  };

  chart.toggleItemHighlight = function (funcktion) {
    if (!arguments.length) {
      return toggleItemHighlight;
    }
    toggleItemHighlight = funcktion;
    return chart;
  };

  chart.responsive_width = function (boolean) {
    if (!arguments.length) {
      return responsive_width;
    }
    responsive_width = boolean;
    return chart;
  };

  chart.bar_label_width = function (int_or_string) {
    if (!arguments.length) {
      return bar_label_width;
    }
    if (int_or_string === "dynamic") {
      bar_label_width = "dynamic";
    } else {
      bar_label_width = parseInt(int_or_string);
    }
    return chart;
  };

  chart.responsive_bar_heights = function (boolean) {
    if (!arguments.length) {
      return responsive_bar_heights;
    }
    responsive_bar_heights = boolean;
    return chart;
  };

  // Use this if you want to manually specify the highest value, for scaling.
  chart.fixed_max_value = function (value) {
    if (!arguments.length) {
      return fixed_max_value;
    }
    fixed_max_value = value;
    return chart;
  };

  chart.highlightedFunction = function (funktion) {
    if (!arguments.length) {
      return highlightedFunction;
    }
    highlightedFunction = funktion;
    return chart;
  };

  chart.dimmedFunction = function (funktion) {
    if (!arguments.length) {
      return dimmedFunction;
    }
    dimmedFunction = funktion;
    return chart;
  };

  chart.tooltipFunction = function (funktion) {
    if (!arguments.length) {
      return tooltipFunction;
    }
    tooltipFunction = funktion;
    return chart;
  };

  chart.labelTooltipFunction = function (funktion) {
    if (!arguments.length) {
      return labelTooltipFunction;
    }
    labelTooltipFunction = funktion;
    return chart;
  };

  return chart;
};

export default HorizontalBarChart;
