/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import moment from "moment";
import d3 from "d3";

const formatDateForServer = function (date) {
  if (date == null) {
    return null;
  }
  return moment(date).format("YYYY-MM-DD");
};

const formatDateForUser = function (date) {
  if (date == null) {
    return null;
  }
  return moment(date).format("M/D/YYYY");
};

const iso8601 = d3.time.format("%Y-%m-%d");

export { formatDateForServer, formatDateForUser, iso8601 };
