/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "lodash";
import { UniqueXAxisValues } from "./unique_x_axis_values";

// A function designed to be used in multi-series d3 charts that assume that
// all of the values arrays of each series will have the same number of members.
//
// If any of the values arrays have missing elements, we create a dummy/placeholder
// value and insert it at the right position within those values arrays.
// A sensible placeholder value is calculated by using x_ and y_value_setters that
// are passed in.
//
// Example input data with the problem:
// [
//   { series_name: "Bronx Health Center",  values: [
//                     { label_name: "2015-03-01", visit_count: 34 }
//                     { label_name: "2015-04-01", visit_count: 68 }
//                  ]
//   },
//
//   { series_name: "Queens Health Center", values: [
//                     { label_name: "2015-01-01", visit_count: 34 },
//                     { label_name: "2015-02-01", visit_count: 58 }
//                     { label_name: "2015-03-01", visit_count: 64 }
//                     { label_name: "2015-05-01", visit_count: 10 }
//                 ]
//   }
// ]
//
// This is a problem because for various elements of the chart -- for example,
// legend values -- if we want to get the data for a particular month, e.g. February,
// then we're going to need to index into each array to get that data.
// Because we access all the February points by index, it's important that February
// have the same index within each series.
//
// Example of how the function will fix the above data:
//
// [
//   { series_name: "Bronx Health Center",  values: [
//                     { label_name: "2015-01-01", visit_count: 34 }  <------------- NEW
//                     { label_name: "2015-02-01", visit_count: 0  }  <------------- NEW
//                     { label_name: "2015-03-01", visit_count: 34 }
//                     { label_name: "2015-04-01", visit_count: 68 }
//                     { label_name: "2015-05-01", visit_count: 0  }  <------------- NEW
//                  ]
//   },
//
//   { series_name: "Queens Health Center", values: [
//                     { label_name: "2015-01-01", visit_count: 34 },
//                     { label_name: "2015-02-01", visit_count: 58 }
//                     { label_name: "2015-03-01", visit_count: 64 }
//                     { label_name: "2015-04-01", visit_count: 0  }  <------------- NEW
//                     { label_name: "2015-05-01", visit_count: 10 }
//                 ]
//   }
// ]
const SparseDataHandler = function (
  data,
  x_value_accessor,
  x_value_setter,
  y_value_setter
) {
  // Copy the data, so as not to be destructive to something passed in by reference.
  const data_copy = _.cloneDeep(data);

  const sorted_x_values = UniqueXAxisValues(data, x_value_accessor);
  // Loop through all the series
  data_copy.forEach((series) =>
    // Loop through all the unique x values
    sorted_x_values.forEach(function (x_value, index) {
      // If you didn't find it...
      if (
        series.values.filter((d) => x_value_accessor(d) === x_value).length ===
        0
      ) {
        // ...and if you are the first of the unique x values,
        const example_object = _.clone(series.values[0]); // (clone the first object in the series as a template)
        if (index === 0) {
          // then prepend the modified example object to the beginning of the values array
          return series.values.unshift(
            y_value_setter(x_value_setter(example_object, x_value), 0)
          );
        }
        // Otherwise, we are going to find the index of the previous unique value, and put you right after it.
        // We know that the previous value will be in there, because if it wasn't already there,
        // because we just put it there!
        const insert_index =
          _.findIndex(
            series.values,
            (d) => x_value_accessor(d) === sorted_x_values[index - 1]
          ) + 1;
        return series.values.splice(
          insert_index,
          0,
          y_value_setter(x_value_setter(example_object, x_value), 0)
        );
      }
    })
  );
  return data_copy;
};

export { SparseDataHandler };
