/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const toggleArrayElement = function (e, elements) {
  if (elements.indexOf(e) === -1) {
    return elements.concat(e);
  }
  return elements.reduce(function (previous, current_element) {
    if (e !== current_element) {
      previous.push(current_element);
    }
    return previous;
  }, []);
};

export { toggleArrayElement };
