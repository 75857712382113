import Behaviors from "./behaviors";
import Charts from "./charts";
import Utils from "./utilities";

const Relevant = {
  ...Behaviors,
  ...Charts,
  ...Utils,
};

export default Relevant;
