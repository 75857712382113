/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "lodash";

const SeriesSums = (x_axis_values, data, x_value_accessor, y_value_accessor) =>
  x_axis_values.map(function (x_axis_value) {
    const values_for_series = data.map((series) =>
      series.values.filter((d) => x_value_accessor(d) === x_axis_value)
    );
    return _.reduce(
      _.flatten(values_for_series),
      (memo, object) => memo + y_value_accessor(object),
      0
    );
  });

export { SeriesSums };
