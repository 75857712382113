/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Measures the width of some text in pixels.
//
// Usage: Relevant.getTextWidth("hello there!", "bold 12pt arial") => ~86

// text       - The string to be measured.
// font_style - The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
//
// Credit: http://stackoverflow.com/a/21015393/183318
const textWidth = function (text, font_style) {
  const canvas =
    Relevant.textWidth.canvas ||
    (Relevant.textWidth.canvas = document.createElement("canvas")); // re-use canvas object for better performance
  const context = canvas.getContext("2d");
  context.font = font_style;
  const metrics = context.measureText(text);
  return metrics.width;
};

export { textWidth };
