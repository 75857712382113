/* eslint-disable */
import d3 from "d3";
import Relevant from "components/relevant";

const TRANSITION_DURATION = 1000;
const ENTER_EXIT_DURATION = TRANSITION_DURATION / 2;
const MINIMUM_BAR_WIDTH = 1;
const BAR_LABEL_FONT_SIZE = 11;
const BAR_ANNOTATION_WIDTH = 45;
const BAR_TEXT_MARGIN = 5;
const MAX_RESPONSIVE_BAR_LINE_HEIGHT = 65;

// The key difference between this component and the HorizontalBarChart is that
// this component accounts for negative values in the bar scales, bar width,
// bar x-position, and annotation x-position.
//
// We also force the bar width to > 1px so that we always draw something,
// even when the computed width would be < 1px.
const HorizontalBarChartWithNegatives = function () {
  const margin = { top: 10, right: 35, bottom: 10, left: 35 };

  // Defaults for accessors
  let width = 600;
  let min_height = 500;

  let calculated_total_height = null;
  let responsive_bar_heights = false;

  let bar_line_height = 20;
  let bar_height = 15;

  let bar_label_width = "dynamic";
  let color_function = () => "#00FF00";
  let highlighted_function = () => false;
  let dimmed_function = () => false;

  let label_accessor = (d) => d.label;
  let label_id_accessor = (d) => d.label_id;
  let labelTooltipFunction = null;
  let value_accessor = (d) => d.value;
  let value_formatter = (d) => d;
  let toggle_item_highlight = null;

  // A property that points to an anonymous function that draws the chart.
  //
  //    selection - A D3 selection containing one or more DOM nodes within which we want the chart to be drawn.
  //                These node(s) [typically only one, but could be more if you want to draw the chart multiple times]
  //                should already have their __data__ element set to the chart's data.
  //
  // The function returns the outer variable that defines it, so calls to it can be chained -- or something. TODO: clarify this.
  var chart = (selection) =>
    // Expects an array as follows:
    //
    //   [
    //     {label: "Bar Corvo", label_id: 1, value: 10},
    //     {label: "Beer Bar", label_id: 2, value: 100},
    //     {label: "Milk and Honey", label_id: 3, value: 90},
    //     {label: "Old Time Tavern", label_id: 4, value: 80}
    //   ]
    //
    // The accessors for: label, label_id and value can be overriden using:
    //   label_accessor, label_id_accessor and value_accessor respectively
    //
    selection.each(function (data, index) {
      width = $(selection[index][0]).width();

      // Bind a callback to the resize event on the initial drawing of the chart.
      // TODO: replace this with _.debounce
      if (selection.select("svg").empty()) {
        let timeout = 0;
        $(window).resize(() => {
          clearTimeout(timeout);
          return (timeout = setTimeout(() => chart(selection), 300));
        });
      }

      const available_width = width - margin.left - margin.right;

      calculated_total_height =
        bar_line_height * data.length + margin.top + margin.bottom;
      const height = d3.max([calculated_total_height, min_height]);
      const available_height = height - margin.top - margin.bottom;

      // Calculate responsive bar heights.
      // Only takes action to fill up min-height if there's extra/unused room within min-height.
      if (responsive_bar_heights && min_height > calculated_total_height) {
        bar_line_height = d3.min([
          MAX_RESPONSIVE_BAR_LINE_HEIGHT,
          available_height / data.length,
        ]);
        bar_height = bar_line_height * 0.85;
      }

      // Sort: first by value_accessor DESC, then break ties with an ASC alpha sort of label text.
      data = data.sort(function (a, b) {
        const sorter = value_accessor(b) - value_accessor(a);

        if (sorter === 0 && label_accessor(a) && label_accessor(b)) {
          return label_accessor(a).localeCompare(label_accessor(b));
        }
        return sorter;
      });

      // Do not truncate the bar length. The shortest bar should be zero
      const extent = d3.extent(data, value_accessor);
      if (extent[0] < 0 && extent[1] < 0) {
        extent[1] = 0;
      }
      if (extent[0] > 0 && extent[1] > 0) {
        extent[0] = 0;
      }
      const bar_scale = d3.scale.linear().domain(extent);

      // If we don't limit the size of bar_label_width, then long label values
      // (e.g., "zzDual Choice Medicare Molina Healthcare Vantage") will break
      // the chart because we won't have enough room to draw the bars.
      //
      // This isn't perfect because the text will overflow off the right of
      // the svg, but it seems good enough. If customers complain, we can
      // investigate drawing the labels as divs nested in foreignObject to
      // get proper overflow behavior.
      const text_width =
        d3.max(data, (d) =>
          Relevant.textWidth(label_accessor(d), "11px sans-serif")
        ) + BAR_TEXT_MARGIN;
      // I picked the scale of 0.6 by trial and error. The bars still look
      // ok even when drawing long labels.
      bar_label_width = d3.min([text_width, width * 0.6]);

      bar_scale.range([
        0,
        available_width - bar_label_width - BAR_ANNOTATION_WIDTH,
      ]);

      // Chart skeleton
      // flow all of the data into an <svg>, if it exists. We use the array so we're asking for one <svg>, not one per datum.
      const svg = d3.select(this).selectAll("svg").data([data]);
      const svg_enter = svg
        .enter()
        .append("svg")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      svg.attr("width", width).attr("height", height);

      // Tooltip skeleton
      const body_selection = d3.select("body");

      body_selection
        .selectAll("div.d3-tooltip")
        .data([0])
        .enter()
        .append("div")
        .attr("class", "d3-tooltip")
        .style("position", "absolute")
        .style("opacity", 0);

      const tooltip_div = body_selection.select("div.d3-tooltip");

      // Bar group
      svg_enter.append("g").attr("class", "bars");

      // Here and below: we need to update all elements that use bar_label_width
      // because it changes with the data.
      const bar_group = svg.select("g.bars");
      bar_group
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("transform", `translate(${bar_label_width}, 0)`);

      // Bar label group
      svg_enter.append("g").attr("class", "bar-labels");

      const bar_label_group = svg.select("g.bar-labels");
      bar_label_group
        .transition()
        .duration(TRANSITION_DURATION)
        .attr(
          "transform",
          `translate(${bar_label_width - BAR_TEXT_MARGIN}, 0)`
        );

      // Bar value annotations group
      svg_enter.append("g").attr("class", "bar-value-annotations");
      const bar_annotation_group = svg.select("g.bar-value-annotations");

      bar_annotation_group
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("transform", `translate(${bar_label_width}, 0)`);

      // Bars
      const bars = bar_group
        .selectAll("rect")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      bars
        .enter()
        .append("rect")
        .style("fill", (d, i) => color_function(label_id_accessor(d), i))
        .style("opacity", 0.1)
        .classed("clickable", true);

      bars
        .attr("height", bar_height)
        .classed("dimmed", (d) => dimmed_function(label_id_accessor(d)))
        .classed("selected", (d) => highlighted_function(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggle_item_highlight) {
            toggle_item_highlight(label_id_accessor(d));
          }
        });

      bars
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("data-test", label_accessor)
        .style("opacity", 1)
        .attr("width", (d) => {
          const value = value_accessor(d);
          const width =
            value < 0
              ? bar_scale(0) - bar_scale(value)
              : bar_scale(value) - bar_scale(0);
          return d3.max([MINIMUM_BAR_WIDTH, width]);
        })
        .attr("x", (d) => {
          const value = value_accessor(d);
          if (value < 0) {
            return d3.min([bar_scale(value), bar_scale(0) - MINIMUM_BAR_WIDTH]);
          }
          return bar_scale(0);
        })
        .attr(
          "y",
          (_d, i) => bar_line_height * i + (bar_line_height - bar_height) / 2
        );

      bars
        .exit()
        .transition()
        .duration(ENTER_EXIT_DURATION)
        .style("opacity", 0.1)
        .delay(ENTER_EXIT_DURATION)
        .remove();

      // Bar labels
      const bar_labels = bar_label_group
        .selectAll("text")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      bar_labels
        .enter()
        .append("text")
        .style("opacity", 0)
        .style("font-size", `${BAR_LABEL_FONT_SIZE}px`)
        .style("alignment-baseline", "middle")
        .attr("class", "bar-chart-bar-label")
        .text((d) => label_accessor(d));

      bar_labels
        .classed("dimmed", (d) => dimmed_function(label_id_accessor(d)))
        .classed("selected", (d) => highlighted_function(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggle_item_highlight) {
            toggle_item_highlight(label_id_accessor(d));
          }
        });

      bar_labels
        .transition()
        .duration(TRANSITION_DURATION)
        .style("opacity", 1)
        .attr("y", (_d, i) => bar_line_height * i + bar_line_height / 2);

      bar_labels
        .exit()
        .transition()
        .duration(ENTER_EXIT_DURATION)
        .style("opacity", 0.1)
        .delay(ENTER_EXIT_DURATION)
        .remove();

      // bar label tooltips
      bar_labels.on("mousemove", function (_d, i) {
        Array.from(d3.mouse(this));
        const d = data[i];
        const tooltipContents = labelTooltipFunction(d);

        if (labelTooltipFunction && tooltipContents) {
          tooltip_div
            .transition()
            .duration(500)
            .style("opacity", 100)
            .style("visibility", "visible");

          return tooltip_div
            .html(tooltipContents)
            .style("left", `${d3.event.pageX + 16}px`)
            .style("top", `${d3.event.pageY}px`);
        }
      });

      bar_labels.on("mouseout", (d) => {
        tooltip_div.transition().duration(500).style("opacity", 0);
      });

      // Bar annotations
      const bar_annotations = bar_annotation_group
        .selectAll("text")
        .data(data, (d) => `${label_accessor(d)}-${label_id_accessor(d)}`);

      bar_annotations
        .enter()
        .append("text")
        .style("font-size", `${BAR_LABEL_FONT_SIZE}px`)
        .style("alignment-baseline", "middle")
        .style("text-anchor", "start")
        .style("opacity", 0)
        .attr("class", "bar-chart-bar-value");

      bar_annotations
        .text((d) => value_formatter(value_accessor(d)))
        .classed("dimmed", (d) => dimmed_function(label_id_accessor(d)))
        .classed("selected", (d) => highlighted_function(label_id_accessor(d)))
        .on("click", (d, i) => {
          if (toggle_item_highlight) {
            toggle_item_highlight(label_id_accessor(d));
          }
        });

      bar_annotations
        .transition()
        .duration(TRANSITION_DURATION)
        .style("opacity", 1)
        .attr("x", (d) => {
          const value = value_accessor(d);
          return (
            (value < 0 ? bar_scale(0) : bar_scale(value)) + BAR_TEXT_MARGIN
          );
        })
        .attr("y", (d, i) => bar_line_height * i + bar_line_height / 2);

      bar_annotations
        .exit()
        .transition()
        .duration(ENTER_EXIT_DURATION)
        .style("opacity", 0.1)
        .delay(ENTER_EXIT_DURATION)
        .remove();

      return chart;
    });

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.min_height = function (value) {
    if (!arguments.length) {
      return min_height;
    }
    min_height = value;
    return chart;
  };

  chart.value_accessor = function (f) {
    if (!arguments.length) {
      return value_accessor;
    }
    value_accessor = f;
    return chart;
  };

  chart.label_accessor = function (f) {
    if (!arguments.length) {
      return label_accessor;
    }
    label_accessor = f;
    return chart;
  };

  chart.labelTooltipFunction = function (funktion) {
    if (!arguments.length) {
      return labelTooltipFunction;
    }
    labelTooltipFunction = funktion;
    return chart;
  };

  chart.label_id_accessor = function (f) {
    if (!arguments.length) {
      return label_id_accessor;
    }
    label_id_accessor = f;
    return chart;
  };

  chart.value_formatter = function (funktion) {
    if (!arguments.length) {
      return value_formatter;
    }
    value_formatter = funktion;
    return chart;
  };

  chart.color_function = function (funktion) {
    if (!arguments.length) {
      return color_function;
    }
    color_function = funktion;
    return chart;
  };

  chart.toggle_item_highlight = function (funcktion) {
    if (!arguments.length) {
      return toggle_item_highlight;
    }
    toggle_item_highlight = funcktion;
    return chart;
  };

  chart.responsive_bar_heights = function (boolean) {
    if (!arguments.length) {
      return responsive_bar_heights;
    }
    responsive_bar_heights = boolean;
    return chart;
  };

  chart.highlighted_function = function (funktion) {
    if (!arguments.length) {
      return highlighted_function;
    }
    highlighted_function = funktion;
    return chart;
  };

  chart.dimmed_function = function (funktion) {
    if (!arguments.length) {
      return dimmed_function;
    }
    dimmed_function = funktion;
    return chart;
  };

  return chart;
};

export default HorizontalBarChartWithNegatives;
