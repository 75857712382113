/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const selectAll = function (context, series_name) {
  const new_data = context.state[series_name];
  new_data.forEach((d) => (d.selected = true));
  const new_state = ({}[series_name] = new_data);
  return context.setState(new_state, context.fetchData);
};

const selectNone = function (context, series_name) {
  const new_data = context.state[series_name];
  new_data.forEach((d) => (d.selected = false));
  const new_state = ({}[series_name] = new_data);
  return context.setState(new_state, context.fetchData);
};

const selectInverse = function (context, series_name) {
  const new_data = context.state[series_name];
  new_data.forEach((d) => (d.selected = !d.selected));
  const new_state = ({}[series_name] = new_data);
  return context.setState(new_state, context.fetchData);
};

export { selectAll, selectNone, selectInverse };
