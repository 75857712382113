/* eslint-disable */
import _ from "lodash";

// Takes a data structure and an x_value_accessor and returns a sorted array of the unique x values.
//
// data             - A data structure. #TODO describe the expectations around what this structure looks like.
// x_value_accessor - An accessor function
const UniqueXAxisValues = function (data, x_value_accessor) {
  // Get all the unique values in the x axis
  const unique_x_values = _.uniq(
    _.flatten(data.map((d) => d.values)).map(x_value_accessor)
  );

  // Sort the unique values.
  // Side note: it's a convenient property of ISO 8601 dates that alphabetical order == chronological order.
  const sorted_x_values = unique_x_values.sort(function (a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return sorted_x_values;
};

export { UniqueXAxisValues };
