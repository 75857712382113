/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Makes dropdowns tagged with the data element {behavior: dropdown} fancy.
// Currently we use the select2 plugin to do the prettifying.
import $ from "jquery";

const Dropdown = {
  initialize() {
    return $(document).on("content:updated", function (e) {
      const $element = $(e.target);
      const $dropDowns = $element.find("[data-behavior~=dropdown]");

      return $dropDowns.each(function () {
        const $item = $(this);

        const select2Options = { placeholder: $(this).data("placeholder") };

        if ($item.data("option-nosearch") === true) {
          select2Options.minimumResultsForSearch = Infinity;
        }

        return $item.select2(select2Options);
      });
    });
  },
};

export default Dropdown;
