/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import d3 from "d3";

const Legend = function () {
  const width = 300;
  const height = 300;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };
  const swatch_height = 10;
  let series_name_accessor = (d) => d.series_name;
  let y_value_formatter = d3.format(",");

  var chart = (selection) =>
    selection.each(function (data) {
      const inner_width = width - margin.left - margin.right;
      const inner_height = height - margin.top - margin.bottom;

      //      selection
      //        .selectAll("text.legend-header")
      //        .data([0])
      //        .enter()
      //        .append("text")
      //        .attr("class", "legend-header")
      //
      //      # we're going to steal the legend header from the top row
      //      selection.selectAll("text.legend-header").text(data[0].name)

      selection
        .selectAll("g.swatch-group")
        .data([0])
        .enter()
        .append("g")
        .attr("class", "swatch-group")
        .attr("transform", "translate(10, 50)");

      const swatch_g = selection.select("g.swatch-group");

      selection
        .selectAll("g.label-group")
        .data([0])
        .enter()
        .append("g")
        .attr("class", "label-group")
        .attr("transform", "translate(10, 50)");

      const label_group = selection.select("g.label-group");

      const swatch_join = swatch_g
        .selectAll("rect")
        .data(data, (d) => series_name_accessor(d));

      swatch_join
        .enter()
        .append("rect")
        .attr("height", swatch_height)
        .attr("width", swatch_height)
        .attr("x", 5);

      swatch_join
        .style("fill", (d, i) => d.color)
        .transition()
        .duration(100)
        .attr("y", (d, i) => i * (swatch_height * 2));

      swatch_join.exit().remove();

      const series_name_text_join = label_group
        .selectAll("text")
        .data(data, (d) => series_name_accessor(d));

      series_name_text_join.enter().append("text").attr("x", 20);

      series_name_text_join
        .transition()
        .duration(100)
        .attr("y", (d, i) => i * (swatch_height * 2) + swatch_height)
        .text(function (d) {
          let result = series_name_accessor(d);
          const val = y_value_formatter(d.value);
          if (val != null && val !== "") {
            result += `: ${val}`;
          }
          return result;
        });

      series_name_text_join.exit().remove();

      return chart;
    });

  chart.series_name_accessor = function (funktion) {
    if (!arguments.length) {
      return series_name_accessor;
    }
    series_name_accessor = funktion;
    return chart;
  };

  chart.y_value_formatter = function (funktion) {
    if (!arguments.length) {
      return y_value_formatter;
    }
    y_value_formatter = funktion;
    return chart;
  };

  return chart;
};

export default Legend;
