/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";

const Datepicker = {
  initialize() {
    return $(document).on("content:updated", function (e) {
      const $element = $(e.target);
      return $element.find("[data-date-picker]").datepicker();
    });
  },
};

export default Datepicker;
