/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import d3 from "d3";

const BinaryStaticPieChart = function () {
  let width = 100;
  let height = 100;
  let label_font_size = 28;
  const color_function = d3.scale.category20c();
  const x_value_accessor = (d) => d.label;
  const y_value_accessor = (d) => +d.count;

  var chart = (selection) =>
    selection.each(function (data, index) {
      const donut_width = 10;
      const outer_radius = height / 2;
      const inner_radius = outer_radius - donut_width;

      const pie_data = data.map((d) => y_value_accessor(d));
      const label_sort = (a, b) => d3.ascending(a.label, b.label);
      const pie_layout = d3.layout.pie().padAngle(0.01).sort(label_sort);
      const arc = d3.svg
        .arc()
        .outerRadius(outer_radius)
        .innerRadius(inner_radius);

      let svg = selection.selectAll("svg").data([0]).enter().append("svg");
      svg = selection.select("svg");

      svg.attr("height", height);
      svg.attr("width", width);
      svg
        .selectAll("g#pie-container")
        .data([0])
        .enter()
        .append("g")
        .attr("id", "pie-container");

      const svg_g = svg.select("g#pie-container");

      svg_g.attr("transform", `translate(${width / 2},${height / 2})`);

      const pie = svg_g.selectAll("path").data(pie_layout(pie_data));

      pie
        .enter()
        .append("path")
        .style("fill", (d, i) => color_function(i))
        .attr("d", arc)
        .each(function (d) {
          return (this._current = d);
        });

      pie
        .transition()
        .duration(400)
        .attrTween("d", function (a) {
          const i = d3.interpolate(this._current, a);
          this._current = i(0);
          return (t) => arc(i(t));
        });

      const total = data.map(y_value_accessor).reduce((t, s) => t + s);

      const formatPercent = d3.format(".1%");

      const percentage = svg.selectAll("text.donut-count").data([0]);

      percentage.enter().append("text").attr("class", "donut-count big-number");

      percentage
        .attr("transform", `translate(0,${label_font_size * 0.9})`)
        .attr("style", `text-anchor: middle; font-size: ${label_font_size}px;`)
        .attr("x", width / 2)
        .attr("y", height / 2 - label_font_size / 2)
        .text((d) => formatPercent(y_value_accessor(data[0]) / total));

      percentage.exit().remove();

      pie.exit().remove();
      return chart;
    });

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.height = function (value) {
    if (!arguments.length) {
      return height;
    }
    height = value;
    return chart;
  };

  chart.label_font_size = function (value) {
    if (!arguments.length) {
      return label_font_size;
    }
    label_font_size = value;
    return chart;
  };

  return chart;
};

export default BinaryStaticPieChart;
