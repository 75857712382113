/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
import _ from "lodash";
import d3 from "d3";
const { OrdinalChartDimension } = require("./chart_dimensions");

const MultiSeriesLineChart = function () {
  let margin = { top: 10, right: 250, bottom: 80, left: 50 };
  let width = 1000;
  let height = 500;

  const default_color_function = d3.scale.category20c();
  const localColorFunction = d3.scale.category20c();
  let external_color_function = null;

  let dot_size = 1;
  let highlight_dot_size = dot_size * 5;
  let highlight_stroke_color = "none";
  let stroke_width = 3;

  let y_axis_label = "Visits";
  let y_value_formatter = (d) => d;
  let y_value_tick_size = [6, 0];
  let y_value_tick_count = 10;

  let series_name_accessor = (d) => (d != null ? d.series_name : undefined);
  let series_id_accessor = (d) => d.series_id;
  let legend_label_accessor = (d) => d;
  let legend_id_accessor = (d) => d;

  let x_value_accessor = (d) => d.label;
  let x_value_setter = function (d, val) {
    d.label = val;
    return d;
  };
  let y_value_accessor = (d) => +d.value;
  let y_value_setter = function (d, val) {
    d.visit_count = val;
    return d;
  };

  let x_dimension_factory = OrdinalChartDimension;

  let responsive_width = true;
  let responsive_height = true;
  const percentage_of_window_innerHeight = 0.75;

  let render_legend = true;
  let render_axes = true;

  let fixed_min = null;
  let fixed_max = null;

  const TRANSITION_DURATION = 400;

  let guideline_x = null;
  let popover_function = null;
  let onPopoverSelectionChanged = null;

  let handle_sparse_data = false;

  let popover_style_key_value_pairs = {};

  let chart_click_handler = null;

  let popover_includes_all_series = false;

  let target = null;

  // Used internally to generate html classes of chart dots
  const sluggify = function (s) {
    if (s !== undefined) {
      return s.split(" ").join("-");
    }
  };

  var chart = function (selection) {
    selection.each(function (data, index) {
      let color_picker;
      let popover;
      let d;
      if (handle_sparse_data) {
        data = Relevant.SparseDataHandler(
          data,
          x_value_accessor,
          x_value_setter,
          y_value_setter
        );
      }

      if (responsive_width || responsive_height) {
        if (responsive_width) {
          width = $(selection[index][0]).width();
        }
        if (responsive_height) {
          height = window.innerHeight * percentage_of_window_innerHeight;
        }

        // Bind a callback to the resize event on the initial drawing of the chart.
        if (selection.select("svg").empty()) {
          let timeout = 0;
          $(window).resize(() => {
            clearTimeout(timeout);
            return (timeout = setTimeout(() => chart(selection), 300));
          });
        }
      }

      const inner_width = width - margin.left - margin.right;
      const inner_height = height - margin.top - margin.bottom;

      if (external_color_function) {
        color_picker = external_color_function;
      } else {
        // If an external_color_function has not been passed in,
        // use the default_color_function to build a hash from series names to colors, so
        // we can pass around a color_picker closure that looks up values from that hash.
        // This is more resilient than assigning colors based on array index.
        const color_hash = {};
        data.forEach(
          (series, index) =>
            (color_hash[series_id_accessor(series)] =
              default_color_function(index))
        );
        color_picker = (d) => color_hash[d];
      }

      // Set up domains, scales, ranges, and axes
      let y_min = d3.min(
        data.map((d) => d3.min(d.values.map((d) => y_value_accessor(d))))
      );
      let y_max = d3.max(
        data.map((d) => d3.max(d.values.map((d) => y_value_accessor(d))))
      );

      // if a target is set, take that into account for the yaxis
      if (target) {
        y_min = Math.min(y_min, target / 100);
        y_max = Math.max(y_max, target / 100);
      }

      const x = x_dimension_factory({
        unique_axis_values: Relevant.UniqueXAxisValues(data, x_value_accessor),
        accessor: x_value_accessor,
        width: inner_width,
      });

      const y = d3.scale
        .linear()
        .rangeRound([inner_height, 0])
        .domain([
          fixed_min === 0 ? 0 : y_min * 0.95,
          fixed_max || y_max * 1.05,
        ]);

      // Chart skeleton
      const svg = selection.selectAll("svg").data([data]);

      const svg_enter = svg.enter().append("svg").attr("class", "main-svg");

      svg.attr("width", width).attr("height", height);

      const main_chart_group_enter = svg_enter
        .append("g")
        .attr("class", "main-chart-group");

      // Get a new reference to main-chart-group (in case it's a refresh)
      const main_chart_group = svg.select("g.main-chart-group");

      main_chart_group
        .attr("width", inner_width)
        .attr("height", inner_height)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const body_selection = d3.select("body");

      if (guideline_x != null) {
        main_chart_group
          .select("line.vertical-guideline")
          .style("visibility", "visible")
          .attr("x1", x.scale(x.parser(guideline_x)))
          .attr("x2", x.scale(x.parser(guideline_x)));
      }

      // if a target was set, show it on the chart
      if (target) {
        const target_line = main_chart_group.selectAll("line.target").data([0]);
        const target_text = main_chart_group
          .selectAll("text.target-label")
          .data([0]);

        target_line.enter().append("line");

        target_line
          .attr("class", "target")
          .attr("style", "stroke: #959595;stroke-width: 1.5px;")
          .style("stroke-dasharray", "3, 3")
          .attr("x1", 0)
          .attr("x2", inner_width)
          .transition()
          .duration(TRANSITION_DURATION)
          .attr("y1", y(target / 100))
          .attr("y2", y(target / 100));

        target_text.enter().append("text");

        target_text
          .attr("class", "target-label")
          .attr("x", inner_width)
          .style("text-anchor", "end")
          .text(`Current target: ${target}%`)
          .transition()
          .duration(TRANSITION_DURATION)
          .attr("y", y(target / 100) - 8);
      }

      if (popover_function != null) {
        body_selection
          .selectAll("div.d3-multiseries-popover")
          .data([0])
          .enter()
          .append("div")
          .attr("class", "d3-multiseries-popover")
          .style("visibility", "hidden")
          .style(popover_style_key_value_pairs);

        popover = body_selection.select("div.d3-multiseries-popover");
      } else if (onPopoverSelectionChanged == null) {
        body_selection
          .selectAll("div.d3-multiseries-popover")
          .style("visibility", "hidden");
      }

      if (render_axes) {
        const xAxis = x.axis;
        const yAxis = d3.svg
          .axis()
          .scale(y)
          .orient("left")
          .tickSize(...y_value_tick_size)
          .tickFormat(y_value_formatter)
          .ticks(y_value_tick_count);

        const x_axis_enter = main_chart_group_enter
          .append("g")
          .attr("class", "x axis");

        const x_axis = main_chart_group
          .select("g.x")
          .attr("transform", `translate(0,${inner_height})`);

        const y_axis_group = main_chart_group.selectAll("g.y.axis").data([0]);

        y_axis_group.enter().append("g").attr("class", "y axis");

        const axis_label = y_axis_group
          .selectAll("text.y-axis-label")
          .data([0]);

        axis_label
          .enter()
          .append("text")
          .attr("class", "y-axis-label")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end");

        axis_label.text(y_axis_label);

        svg
          .select("g.x.axis")
          .call(xAxis)
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", () => "rotate(-65)");

        svg
          .select("g.y.axis")
          .transition()
          .duration(TRANSITION_DURATION)
          .call(yAxis);
      }

      if (render_legend) {
        const legend_group_enter = svg_enter
          .append("g")
          .attr("class", "legend-group");

        const legend_group = svg
          .select("g.legend-group")
          .attr(
            "transform",
            `translate(${margin.left + inner_width},${margin.top})`
          );

        const unique_legend_entries = {};
        for (const v of Array.from(x.unique_axis_values)) {
          for (d of Array.from(Relevant.valuesForKey(data, v, x.accessor))) {
            unique_legend_entries[legend_label_accessor(d)] = d;
          }
        }

        const legend_data_points = (() => {
          const result = [];
          for (const k in unique_legend_entries) {
            d = unique_legend_entries[k];
            result.push(d);
          }
          return result;
        })();
        const legend_data = legend_data_points
          .map((d, i) => ({
            series_name: legend_label_accessor(d),
            color: color_picker(legend_id_accessor(d), localColorFunction(i)),
            value: y_value_accessor(d),
          }))
          .sort((a, b) => b.value - a.value);

        const legend_g = selection.select("g.legend-group");
        legend_g.datum(legend_data);
        const legend = Relevant.Legend().y_value_formatter(() => "");
        legend(legend_g);
      }

      main_chart_group
        .append("clipPath")
        .attr("id", "chart-clip")
        .append("rect")
        .attr("width", inner_width)
        .attr("height", inner_height);

      // Series
      const series_group = main_chart_group
        .selectAll("g.series")
        .data(data, (d) => series_name_accessor(d));

      const series_enter = series_group.enter().append("g");

      series_group
        .attr("class", (d) => `series series-${series_id_accessor(d)}`)
        .attr("clip-path", "url(#chart-clip)")
        .style("fill", (d, i) =>
          color_picker(series_id_accessor(d), localColorFunction(i))
        )
        .style("stroke", (d, i) =>
          color_picker(series_id_accessor(d, localColorFunction(i)))
        );

      series_group
        .exit()
        .transition()
        .duration(750)
        .style("opacity", 1e-6)
        .remove();

      // Data points: join
      const data_points = series_group.selectAll(".data-point").data(
        (d) => d.values,
        (d) => x.accessor(d) + series_name_accessor(d)
      ); // use label_id and series_name to uniquely identify series

      // Data points: enter
      data_points
        .enter()
        .append("circle")
        .attr("class", (d) => `data-point time-id-${sluggify(x.accessor(d))}`)
        .style("opacity", 0);

      // Data points: update
      data_points
        .attr("cx", (d) => x.scale(x.parsed_accessor(d)))
        .attr("r", dot_size)
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("cy", (d) => y(y_value_accessor(d)))
        .style("opacity", 1);

      data_points
        .exit()
        .transition()
        .duration(750)
        .style("opacity", 1e-6)
        .remove();

      // Define a line function
      const historical_line_function = d3.svg
        .line()
        .x((d) => x.scale(x.parsed_accessor(d)))
        .y((d) => y(y_value_accessor(d)))
        .defined((d) => !d.projected);

      const projected_line_function = d3.svg
        .line()
        .x((d) => x.scale(x.parsed_accessor(d)))
        .y((d) => y(y_value_accessor(d)))
        .defined((d) => d.projected || d.last_historical);

      // Lines: join
      const historical_lines = series_group.selectAll(".chart-line").data(
        (d) => [d.values],
        (d) => series_name_accessor(d[0])
      );

      const projected_lines = series_group
        .selectAll(".chart-projected-line")
        .data(
          (d) => [d.values],
          (d) => series_name_accessor(d[0])
        );

      // Lines: enter
      historical_lines
        .enter()
        .append("path")
        .style("opacity", 0)
        .style("stroke-width", stroke_width)
        .attr("class", "chart-line");

      projected_lines
        .enter()
        .append("path")
        .style("opacity", 0)
        .style("stroke-width", stroke_width)
        .style("stroke-dasharray", 3)
        .attr("class", "chart-projected-line");

      // Lines: update
      historical_lines
        .transition()
        .duration(TRANSITION_DURATION)
        .style("opacity", 1)
        .attr("d", (d) => historical_line_function(d));

      projected_lines
        .transition()
        .duration(TRANSITION_DURATION)
        .style("opacity", 1)
        .attr("d", (d) => projected_line_function(d));

      historical_lines.exit().remove();
      projected_lines.exit().remove();

      const vertical_guideline = main_chart_group
        .selectAll("line.vertical-guideline")
        .data([0]);

      vertical_guideline
        .enter()
        .append("line")
        .attr("class", "vertical-guideline")
        .style("visibility", "hidden")
        .style("opacity", 0.5);

      vertical_guideline.attr("y1", 0).attr("y2", inner_height);

      svg
        .selectAll("g.listening-group")
        .data([0])
        .enter()
        .append("g")
        .attr("class", "listening-group")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const mouse_listening_rect = svg
        .select("g.listening-group")
        .selectAll(".mouse-listening-rectangle")
        .data([0]);

      mouse_listening_rect
        .enter()
        .append("rect")
        .attr("class", "mouse-listening-rectangle")
        .attr("fill", "none")
        .attr("pointer-events", "all");

      mouse_listening_rect
        .attr("width", inner_width)
        .attr("height", inner_height);

      // Dynamic legend presenting the data point for each series at the hovered point in time.
      mouse_listening_rect.on("mousemove", function () {
        const [mouse_x, mouse_y] = Array.from(d3.mouse(this));

        const y_close_to_mouse = (y) => Math.abs(y - mouse_y) < 20;

        if (popover_function != null || onPopoverSelectionChanged != null) {
          const lookup_value = x.axis_value_from_mouse_x(mouse_x);
          const values = Relevant.valuesForKey(data, lookup_value, x.accessor);
          let filtered_values = values;
          if (!popover_includes_all_series) {
            filtered_values = values.filter((v) =>
              y_close_to_mouse(y(y_value_accessor(v)))
            );
          }
          const left = d3.event.pageX + 10;
          const top = d3.event.pageY;
          if (popover_function != null) {
            if (filtered_values.length === 0) {
              d3.select(this).style("cursor", "default");
              popover.style("visibility", "hidden");
            } else {
              if (chart_click_handler) {
                d3.select(this).style("cursor", "pointer");
              }
              popover
                .style("visibility", "visible")
                .html(
                  popover_function(
                    filtered_values.map((v) => ({
                      ...v,
                      color: color_picker(series_id_accessor(v)),
                    }))
                  )
                )
                .style("left", `${left}px`)
                .style("top", `${top}px`);
            }
          } else if (onPopoverSelectionChanged != null) {
            onPopoverSelectionChanged({
              layout: {
                left: mouse_x + 80,
                top: mouse_y + 80,
                visible: filtered_values.length !== 0,
              },
              data: filtered_values,
            });
          }
        }

        main_chart_group
          .selectAll("circle.data-point")
          .attr("r", dot_size)
          .style("stroke", "none");

        return main_chart_group
          .selectAll(
            `circle.time-id-${sluggify(x.axis_value_from_mouse_x(mouse_x))}`
          )
          .attr("r", function (...x) {
            if (y_close_to_mouse(d3.select(this).attr("cy"))) {
              return highlight_dot_size;
            }
            return dot_size;
          })
          .style("stroke", function (...x) {
            if (y_close_to_mouse(d3.select(this).attr("cy"))) {
              return highlight_stroke_color;
            }
            return "none";
          });
      });

      mouse_listening_rect.on("mouseout", function () {
        if (popover != null) {
          popover.style("visibility", "hidden");
        }
        return main_chart_group
          .selectAll("circle.data-point")
          .attr("r", dot_size);
      });

      return mouse_listening_rect.on("click", function () {
        // right now we are just picking out the value at the index of the first row,
        // which works great for the current use case, but could change down the line.
        const lookup_value = x.axis_value_from_mouse_x(d3.mouse(this)[0]);
        const object_for_click_handler = Relevant.valuesForKey(
          data,
          lookup_value,
          x.accessor
        )[0];
        if (chart_click_handler) {
          chart_click_handler(object_for_click_handler);
        }
      });
    });

    return chart;
  };

  chart.margin = function (margin_object) {
    if (!arguments.length) {
      return margin;
    }
    margin = margin_object;
    return chart;
  };

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.height = function (value) {
    if (!arguments.length) {
      return height;
    }
    height = value;
    return chart;
  };

  chart.stroke_width = function (value) {
    if (!arguments.length) {
      return stroke_width;
    }
    stroke_width = value;
    return chart;
  };

  chart.dot_size = function (value) {
    if (!arguments.length) {
      return dot_size;
    }
    dot_size = value;
    return chart;
  };

  chart.highlight_dot_size = function (value) {
    if (!arguments.length) {
      return highlight_dot_size;
    }
    highlight_dot_size = value;
    return chart;
  };

  chart.highlight_stroke_color = function (value) {
    if (!arguments.length) {
      return highlight_stroke_color;
    }
    highlight_stroke_color = value;
    return chart;
  };

  chart.y_axis_label = function (value) {
    if (!arguments.length) {
      return y_axis_label;
    }
    y_axis_label = value;
    return chart;
  };

  chart.external_color_function = function (funktion) {
    if (!arguments.length) {
      return external_color_function;
    }
    external_color_function = funktion;
    return chart;
  };

  chart.responsive_width = function (boolean) {
    if (!arguments.length) {
      return responsive_width;
    }
    responsive_width = boolean;
    return chart;
  };

  chart.responsive_height = function (boolean) {
    if (!arguments.length) {
      return responsive_height;
    }
    responsive_height = boolean;
    return chart;
  };

  chart.render_legend = function (boolean) {
    if (!arguments.length) {
      return render_legend;
    }
    render_legend = boolean;
    return chart;
  };

  chart.render_axes = function (boolean) {
    if (!arguments.length) {
      return render_axes;
    }
    render_axes = boolean;
    return chart;
  };

  chart.target = function (value) {
    if (!arguments.length) {
      return target;
    }
    target = value;
    return chart;
  };

  chart.fixed_min = function (value) {
    if (!arguments.length) {
      return fixed_min;
    }
    fixed_min = value;
    return chart;
  };

  chart.fixed_max = function (value) {
    if (!arguments.length) {
      return fixed_max;
    }
    fixed_max = value;
    return chart;
  };

  chart.guideline_x = function (value) {
    if (!arguments.length) {
      return guideline_x;
    }
    guideline_x = value;
    return chart;
  };

  chart.popover_function = function (value) {
    if (!arguments.length) {
      return popover_function;
    }
    popover_function = value;
    return chart;
  };

  chart.popover_style_key_value_pairs = function (value) {
    if (!arguments.length) {
      return popover_style_key_value_pairs;
    }
    popover_style_key_value_pairs = value;
    return chart;
  };

  chart.onPopoverSelectionChanged = function (value) {
    if (!arguments.length) {
      return onPopoverSelectionChanged;
    }
    onPopoverSelectionChanged = value;
    return chart;
  };

  chart.y_value_tick_size = function (value) {
    if (!arguments.length) {
      return y_value_tick_size;
    }
    y_value_tick_size = value;
    return chart;
  };

  chart.y_value_tick_count = function (value) {
    if (!arguments.length) {
      return y_value_tick_count;
    }
    y_value_tick_count = value;
    return chart;
  };

  chart.chart_click_handler = function (funktion) {
    if (!arguments.length) {
      return chart_click_handler;
    }
    chart_click_handler = funktion;
    return chart;
  };

  chart.x_value_accessor = function (f) {
    if (!arguments.length) {
      return x_value_accessor;
    }
    x_value_accessor = f;
    return chart;
  };

  chart.y_value_accessor = function (funktion) {
    if (!arguments.length) {
      return y_value_accessor;
    }
    y_value_accessor = funktion;
    return chart;
  };

  chart.y_value_setter = function (funktion) {
    if (!arguments.length) {
      return y_value_setter;
    }
    y_value_setter = funktion;
    return chart;
  };

  chart.x_value_setter = function (funktion) {
    if (!arguments.length) {
      return x_value_setter;
    }
    x_value_setter = funktion;
    return chart;
  };

  chart.y_value_formatter = function (funktion) {
    if (!arguments.length) {
      return y_value_formatter;
    }
    y_value_formatter = funktion;
    return chart;
  };

  chart.series_name_accessor = function (f) {
    if (!arguments.length) {
      return series_name_accessor;
    }
    series_name_accessor = f;
    return chart;
  };

  chart.series_id_accessor = function (f) {
    if (!arguments.length) {
      return series_id_accessor;
    }
    series_id_accessor = f;
    return chart;
  };

  chart.legend_label_accessor = function (f) {
    if (!arguments.length) {
      return legend_label_accessor;
    }
    legend_label_accessor = f;
    return chart;
  };

  chart.legend_id_accessor = function (f) {
    if (!arguments.length) {
      return legend_id_accessor;
    }
    legend_id_accessor = f;
    return chart;
  };

  chart.handle_sparse_data = function (value) {
    if (!arguments.length) {
      return handle_sparse_data;
    }
    handle_sparse_data = value;
    return chart;
  };

  chart.x_dimension_factory = function (f) {
    if (!arguments.length) {
      return x_dimension_factory;
    }
    x_dimension_factory = f;
    return chart;
  };

  chart.popover_includes_all_series = function (value) {
    if (!arguments.length) {
      return popover_includes_all_series;
    }
    popover_includes_all_series = value;
    return chart;
  };

  return chart;
};

export default MultiSeriesLineChart;
