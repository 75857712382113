/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
import d3 from "d3";
import moment from "moment";

const CalendarHeatmap = function () {
  let width = 300;
  let height = 1500;
  const inner_width = 200;
  const inner_height = 980;
  let cell_size = 21;
  let margin = { top: 10, bottom: 10, left: 30, right: 10 };

  let start_date = new Date(2014, 0, 1);
  let end_date = new Date(2014, 11, 31);

  const center = true;
  let label_position = "side";

  const responsive_height = false;
  const percentage_of_window_innerHeight = 0.95;

  const day_of_week = d3.time.format("%w");
  let week_of_year = d3.time.format("%U");
  const percent = d3.format(".1%");
  const add_comma = d3.format(",");
  const formatDateForServer = d3.time.format("%Y-%m-%d");
  const formatDateForUser = (d) => moment(d).format("MMMM DD, YYYY");
  const transition_duration = 850;
  let colorFunction = d3.scale.linear().range(["#FFFFFF", "#0061C9"]);

  let domainFunction = function (data) {
    const min = 0;
    const max = d3.max(data, (d) => count_value_accessor(d)) || 0;
    return [min, max];
  };

  const multiYearWeekScale = function (
    begin_date,
    end_date,
    scale_factor,
    date
  ) {
    const years_in_range_before_date = d3
      .range(begin_date.getFullYear(), end_date.getFullYear())
      .filter((d) => d < date.getFullYear());
    week_of_year = d3.time.format("%U");
    const beginning_of_month_of_begin_date = moment(begin_date)
      .startOf("month")
      .toDate();
    const baseline = +week_of_year(beginning_of_month_of_begin_date);
    let weeks_in_years_before_date = 0;
    years_in_range_before_date.forEach(function (year) {
      const last_day_of_year = moment(new Date(year, 0, 1))
        .endOf("year")
        .toDate();
      if (last_day_of_year.getDay() === 6) {
        return (weeks_in_years_before_date +=
          +week_of_year(last_day_of_year) - 1); // if the year ends on a saturday, don't add another week step
      }
      return (weeks_in_years_before_date += +week_of_year(last_day_of_year));
    });
    return (
      (+week_of_year(date) + weeks_in_years_before_date - baseline) *
      scale_factor
    );
  };

  let currently_selected_date = "";

  // Accessors sensible defaults
  let clickHandler = (d) => d;
  let date_value_accessor = (d) => d.date;
  var count_value_accessor = (d) => d.count;

  var chart = (selection) =>
    selection.each(function (data, index) {
      // <setup>

      start_date = moment(start_date).startOf("month").toDate();
      end_date = moment(end_date).endOf("month").toDate();

      const report_years = d3.range(
        start_date.getFullYear(),
        end_date.getFullYear() + 1
      );
      const report_months = d3.time.months(
        moment(start_date).startOf("month").toDate(),
        end_date
      );
      const monthLabelFormatter = d3.time.format("%b");

      const data_hash = {};

      data.forEach(
        (d) => (data_hash[date_value_accessor(d)] = count_value_accessor(d))
      );

      const days_data = d3.time.days(start_date, end_date).map((d) => ({
        date: d,
        value: data_hash[formatDateForServer(d)] || 0,
      }));

      const domain_extent = domainFunction(data);
      colorFunction.domain(domain_extent);

      const formatDateForComparison = function (date) {
        if (date == null) {
          return null;
        }
        return moment(date).format("YYYY-MM-DD");
      };

      const curriedWeekScaleWithScaleFactor = (date) =>
        multiYearWeekScale(start_date, end_date, cell_size, date);

      const curriedWeekScaleWithoutScaleFactor = (date) =>
        multiYearWeekScale(start_date, end_date, 1, date);

      if (responsive_height) {
        height = window.innerHeight * percentage_of_window_innerHeight;
        cell_size = Math.floor(height / 53);
        // Bind a debounced callback to the resize event on the initial drawing of the chart.
        if (selection.select("svg").empty()) {
          let timeout = 0;
          $(window).resize(() => {
            clearTimeout(timeout);
            return (timeout = setTimeout(() => chart(selection), 300));
          });
        }
      }

      if (center) {
        margin.left = (width - cell_size * 7) / 2;
      }

      // </setup>

      const svg = selection.selectAll("svg").data([0]);

      const svg_enter = svg.enter().append("svg").attr("class", "main-svg");

      svg.attr("width", width).attr("height", height);

      const main_chart_group_enter = svg_enter
        .append("g")
        .attr("class", "main-chart-group");

      const main_chart_group = svg.select("g.main-chart-group");

      main_chart_group
        .attr("width", inner_width)
        .attr("height", inner_height)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const verticalMonthPath = function (month_date) {
        const first_day_of_next_month = new Date(
          month_date.getFullYear(),
          month_date.getMonth() + 1,
          0
        );
        const last_day_of_this_month = moment(first_day_of_next_month)
          .endOf("month")
          .toDate();

        const day_of_week_of_first_day_of_month = +day_of_week(month_date);
        const week_of_year_of_first_day_of_month =
          curriedWeekScaleWithoutScaleFactor(month_date);

        const day_of_week_of_last_day_of_month = +day_of_week(
          last_day_of_this_month
        );
        const week_of_year_of_last_day_of_month =
          curriedWeekScaleWithoutScaleFactor(last_day_of_this_month);

        const day_of_week_of_first_day_of_next_month = +day_of_week(
          first_day_of_next_month
        );
        const week_of_year_of_first_day_of_next_month =
          curriedWeekScaleWithoutScaleFactor(first_day_of_next_month);

        return `${
          `M${day_of_week_of_first_day_of_month * cell_size},${
            (week_of_year_of_first_day_of_month + 1) * cell_size
          }` + "V"
        }${week_of_year_of_first_day_of_month * cell_size}H${7 * cell_size}V${
          week_of_year_of_first_day_of_next_month * cell_size
        }H${(day_of_week_of_first_day_of_next_month + 1) * cell_size}V${
          (week_of_year_of_first_day_of_next_month + 1) * cell_size
        }H${0}V${(week_of_year_of_first_day_of_month + 1) * cell_size}Z`;
      };
      if (label_position === "side") {
        const year_labels = main_chart_group.selectAll("text.year-label").data(
          report_years.map((d) => ({ date: d })),
          (d) => d.date
        );

        year_labels
          .enter()
          .append("text")
          .attr("class", "year-label")
          .style("text-anchor", "middle");

        year_labels
          .text((d) => d.date)
          .transition()
          .duration(transition_duration)
          .attr("y", (d) =>
            curriedWeekScaleWithScaleFactor(new Date(d.date, 0, 1))
          )
          .attr(
            "transform",
            (d) =>
              `translate(-20,${
                cell_size * 2
              }${`)rotate(-90 0 ${curriedWeekScaleWithScaleFactor(
                new Date(d.date, 0, 1)
              )})`}`
          );

        year_labels.exit().remove();

        const month_labels = main_chart_group
          .selectAll("text.month-label")
          .data(
            report_months.map((d) => ({ date: d })),
            (d) => d.date
          );

        month_labels
          .enter()
          .append("text")
          .attr("class", "month-label")
          .style("text-anchor", "middle");

        month_labels
          .text((d) => monthLabelFormatter(d.date))
          .transition()
          .duration(transition_duration)
          .attr("y", (d) => curriedWeekScaleWithScaleFactor(d.date))
          .attr(
            "transform",
            (d) =>
              `translate(-6,${
                cell_size * 2
              }${`)rotate(-90 0 ${curriedWeekScaleWithScaleFactor(d.date)})`}`
          );

        month_labels.exit().remove();
      }

      const days_group = main_chart_group.selectAll("g.days-group").data([0]);

      days_group.enter().append("g").attr("class", "days-group");

      const days = days_group.selectAll("rect.day").data(
        (d) => days_data,
        (d) => d.date
      );

      days
        .enter()
        .append("rect")
        .attr("class", "day clickable")
        .attr("width", cell_size)
        .attr("height", cell_size);

      days
        .classed(
          "selected",
          (d) =>
            formatDateForComparison(d.date) ===
            formatDateForComparison(currently_selected_date)
        )
        .on("click", (d, i) => clickHandler(d.date));

      days
        .transition()
        .duration(transition_duration)
        .attr("x", (d) => day_of_week(d.date) * cell_size)
        .attr("y", (d) => curriedWeekScaleWithScaleFactor(d.date))
        .style("fill", (d) => colorFunction(d.value || 0));

      days.append("title").text((d) => formatDateForUser(d.date));

      days.exit().remove();

      const months = main_chart_group
        .selectAll("path.month")
        .data((d) => report_months);

      months.enter().append("path").attr("class", "month");

      months.attr("d", verticalMonthPath);

      months.exit().remove();
      return chart;
    });

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.height = function (value) {
    if (!arguments.length) {
      return height;
    }
    height = value;
    return chart;
  };

  chart.margin = function (value) {
    if (!arguments.length) {
      return margin;
    }
    margin = value;
    return chart;
  };

  chart.cell_size = function (value) {
    if (!arguments.length) {
      return cell_size;
    }
    cell_size = value;
    return chart;
  };

  chart.label_position = function (value) {
    if (!arguments.length) {
      return label_position;
    }
    label_position = value;
    return chart;
  };

  chart.start_date = function (value) {
    if (!arguments.length) {
      return start_date;
    }
    start_date = value;
    return chart;
  };

  chart.end_date = function (value) {
    if (!arguments.length) {
      return end_date;
    }
    end_date = value;
    return chart;
  };

  chart.currently_selected_date = function (value) {
    if (!arguments.length) {
      return currently_selected_date;
    }
    currently_selected_date = value;
    return chart;
  };

  chart.clickHandler = function (funktion) {
    if (!arguments.length) {
      return clickHandler;
    }
    clickHandler = funktion;
    return chart;
  };

  chart.colorFunction = function (funktion) {
    if (!arguments.length) {
      return colorFunction;
    }
    colorFunction = funktion;
    return chart;
  };

  chart.domainFunction = function (funktion) {
    if (!arguments.length) {
      return domainFunction;
    }
    domainFunction = funktion;
    return chart;
  };

  chart.date_value_accessor = function (f) {
    if (!arguments.length) {
      return date_value_accessor;
    }
    date_value_accessor = f;
    return chart;
  };

  chart.count_value_accessor = function (f) {
    if (!arguments.length) {
      return count_value_accessor;
    }
    count_value_accessor = f;
    return chart;
  };

  return chart;
};

export default CalendarHeatmap;
