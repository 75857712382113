/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import $ from "jquery";
import _ from "lodash";
import d3 from "d3";

const BarChart = function () {
  const margin = { top: 15, right: 10, bottom: 80, left: 50 };

  const responsive_width = true;
  const render_axes = true;

  let width = 500;
  let height = 400;

  const TRANSITION_DURATION = 1000;

  let x_value_accessor = (d) => d.age;
  let y_value_accessor = (d) => d.patient_count;
  let y_value_formatter = d3.format(".2s");
  let x_axis_sort_function = (d) => d;
  let label_id_accessor = (d) => d.label_id;
  let toggleItemHighlight = (d) => d;
  let highlightedFunction = (d) => false;
  let dimmedFunction = (d) => false;

  const y_axis_label = "";

  var chart = (selection) =>
    selection.each(function (data, index) {
      const svg = selection.selectAll("svg").data([data]);

      if (responsive_width) {
        width = $(selection[index][0]).width();

        // TODO: replace this with _.debounce
        // Bind a callback to the resize event on the initial drawing of the chart.
        if (selection.select("svg").empty()) {
          let timeout = 0;
          $(window).resize(() => {
            clearTimeout(timeout);
            return (timeout = setTimeout(() => chart(selection), 300));
          });
        }
      }

      const inner_width = width - margin.left - margin.right;
      const inner_height = height - margin.top - margin.bottom;
      const x = d3.scale.ordinal().rangeRoundBands([0, inner_width], 0.1);
      const y = d3.scale.linear().rangeRound([inner_height, 0]);
      const xAxis = d3.svg.axis().scale(x).orient("bottom");
      const yAxis = d3.svg
        .axis()
        .scale(y)
        .orient("left")
        .tickFormat(y_value_formatter);

      const svg_enter = svg.enter().append("svg").attr("class", "main-svg");

      svg.attr("width", width).attr("height", height);

      const main_chart_group_enter = svg_enter
        .append("g")
        .attr("class", "main-chart-group");

      const main_chart_group = svg.select("g.main-chart-group");

      main_chart_group
        .attr("width", inner_width)
        .attr("height", inner_height)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      x.domain(x_axis_sort_function(data.map(x_value_accessor)));

      const y_values = data.map(y_value_accessor);
      const extent = d3.extent(y_values);

      // Do not truncate the y-axis: always show the zero line, even when
      // the y-domain is either entirely positive or negative.
      if (extent[0] < 0 && extent[1] < 0) {
        extent[1] = 0;
      }
      if (extent[0] > 0 && extent[1] > 0) {
        extent[0] = 0;
      }
      y.domain(extent);

      const color = d3.scale
        .ordinal()
        .domain(x.domain())
        .range(d3.scale.category20c().range());

      const bars = main_chart_group
        .selectAll("rect")
        .data(data, (d) => label_id_accessor(d));

      bars
        .classed("dimmed", (d) => dimmedFunction(label_id_accessor(d)))
        .classed("selected", (d) => highlightedFunction(label_id_accessor(d)))
        .on("click", (d, i) => toggleItemHighlight(label_id_accessor(d)));

      bars
        .enter()
        .append("rect")
        .classed("bar-chart-bar", true)
        .classed("clickable", true)
        .attr("fill", (d) => color(x_value_accessor(d)))
        .attr("height", 0)
        .attr("y", y(0))
        .attr("transform", (d) => `translate(${x(x_value_accessor(d))},0)`);

      bars
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("width", x.rangeBand())
        .attr("height", (d) => {
          const y_value = y_value_accessor(d);
          return y_value < 0 ? y(y_value) - y(0) : y(0) - y(y_value);
        })
        .attr("y", (d) => {
          const y_value = y_value_accessor(d);
          return y_value < 0 ? y(0) : y(y_value);
        })
        .attr("transform", (d) => `translate(${x(x_value_accessor(d))},0)`);

      bars
        .exit()
        .transition()
        .duration(TRANSITION_DURATION)
        .attr("y", y(0))
        .attr("height", 0)
        .remove();

      if (render_axes) {
        // Create and style the x axis
        main_chart_group_enter.append("g").attr("class", "x axis");

        svg
          .select("g.x.axis")
          .transition()
          .duration(TRANSITION_DURATION)
          .call(xAxis);

        svg
          .select("g.x.axis")
          .attr("transform", `translate(0,${inner_height})`)
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", () => "rotate(-65)");

        // Create and style the y axis
        const y_axis_group = main_chart_group.selectAll("g.y.axis").data([0]);
        y_axis_group.enter().append("g").attr("class", "y axis");
        const axis_label = y_axis_group
          .selectAll("text.y-axis-label")
          .data([0]);

        axis_label
          .enter()
          .append("text")
          .attr("class", "y-axis-label")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end");

        axis_label.text(y_axis_label);

        svg
          .select("g.y.axis")
          .transition()
          .duration(TRANSITION_DURATION)
          .call(yAxis);

        // Create and style the line at y = 0
        main_chart_group_enter
          .append("g")
          .attr("class", "y0 axis")
          .call(xAxis)
          .attr("transform", `translate(0,${y(0)})`);

        const zero_line = main_chart_group.select("g.y0");

        zero_line
          .transition()
          .duration(TRANSITION_DURATION)
          .attr("transform", `translate(0,${y(0)})`)
          .call(xAxis);

        zero_line.selectAll(".tick").remove();
      }

      return chart;
    });

  chart.x_axis_sort_function = function (funktion) {
    if (!arguments.length) {
      return x_axis_sort_function;
    }
    x_axis_sort_function = funktion;
    return chart;
  };

  chart.x_value_accessor = function (funktion) {
    if (!arguments.length) {
      return x_value_accessor;
    }
    x_value_accessor = funktion;
    return chart;
  };

  chart.y_value_accessor = function (funktion) {
    if (!arguments.length) {
      return y_value_accessor;
    }
    y_value_accessor = funktion;
    return chart;
  };

  chart.y_value_formatter = function (funktion) {
    if (!arguments.length) {
      return y_value_formatter;
    }
    y_value_formatter = funktion;
    return chart;
  };

  chart.height = function (value) {
    if (!arguments.length) {
      return height;
    }
    height = value;
    return chart;
  };

  chart.width = function (value) {
    if (!arguments.length) {
      return width;
    }
    width = value;
    return chart;
  };

  chart.label_id_accessor = function (funktion) {
    if (!arguments.length) {
      return label_id_accessor;
    }
    label_id_accessor = funktion;
    return chart;
  };

  chart.toggleItemHighlight = function (funktion) {
    if (!arguments.length) {
      return toggleItemHighlight;
    }
    toggleItemHighlight = funktion;
    return chart;
  };

  chart.highlightedFunction = function (funktion) {
    if (!arguments.length) {
      return highlightedFunction;
    }
    highlightedFunction = funktion;
    return chart;
  };

  chart.dimmedFunction = function (funktion) {
    if (!arguments.length) {
      return dimmedFunction;
    }
    dimmedFunction = funktion;
    return chart;
  };

  return chart;
};

export default BarChart;
