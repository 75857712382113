/* eslint-disable */
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "lodash";

// So this guy is useful for creating summaries of data at a certain point in time,
// for example, when you need to create a legend or popover. See spec for reference
// implementation
const valuesForKey = (data, lookup_value, x_value_accessor) =>
  _(data)
    .flatMap((d) =>
      _.find(d.values, (e) => x_value_accessor(e) === lookup_value)
    )
    .reject(_.isUndefined)
    .reject(_.isNull)
    .value();

export { valuesForKey };
