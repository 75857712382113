/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Initialize Bootstrap's tooltip functionality.
import $ from "jquery";

const Tooltip = {
  initialize() {
    return $(document).on("mouseenter", "[data-toggle='tooltip']", (element) =>
      $(element.currentTarget).tooltip("show")
    );
  },
};

export default Tooltip;
