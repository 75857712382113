/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Helper methods to open/close a React-powered SelectBox component.
import $ from "jquery";

const Selectbox = {
  initialize() {
    return $(document).on("content:updated", (e) => {
      const $element = $(e.target);
      return this.applyToElement($element);
    });
  },

  applyToElement($element) {
    this.registerSelectboxToggleClickHandlers($element);
    this.registerSelectboxCloseButtonHandlers($element);
    return this.registerClickAnywhereOutsideSelectboxHandler($element);
  },

  registerSelectboxToggleClickHandlers($element) {
    return $element.on("click", "[data-toggle~=selectbox]", function (e) {
      $(".selectbox").not($(this).parent()).removeClass("open"); // close any other selectboxes that might currently be open
      $(this).parent().toggleClass("open");
      const $search_input = $(this).parent().find("input.search")[0];
      if ($search_input != null) {
        $search_input.focus();
      }
      return e.preventDefault;
    });
  },

  registerSelectboxCloseButtonHandlers($element) {
    return $element.on("click", "[data-dismiss~=selectbox-menu]", function (e) {
      $(this).closest(".selectbox").removeClass("open");
      return e.preventDefault();
    });
  },

  // Close any open selectboxes. Not sure if this is the best way at all...
  // Maybe faster to just *check* if there are any open selectboxes before doing all this selector stuff? because
  // typically, on most clicks, there won't be an open selectbox, and this will be a no-op.
  registerClickAnywhereOutsideSelectboxHandler($element) {
    return $element.on("click", function (e) {
      if (
        !$(".selectbox").is(e.target) && // If selectbox isn't the target...
        $(".selectbox").has(e.target).length === 0 && // and a child of selectbox isn't the target...
        $(".open").has(e.target).length === 0
      ) {
        // and a child of open isn't the target...
        return $(".selectbox").removeClass("open");
      }
    });
  }, // close all selectboxes.
};

export default Selectbox;
